<template>
  <div>
    <v-container fluid class="wp-page past-events">
      <!-- <page-title class="hidden-xs-only" :page="page" /> -->
      <Breadcrumb :items="breadcrumb" />
      <v-container v-if="page && page.content">
        <v-runtime-template
          :template="page.content.rendered"
          @click="clicked"
        />
      </v-container>
      <div class="bg-grey">
        <v-container class="mt-0 pt-0 pb-12">
          <v-select
            v-if="tags"
            outlined
            hide-details
            single-line
            clearable
            v-model="tagId"
            :items="tags"
            item-text="name"
            item-value="id"
            :label="$t('events.filter.label')"
            :aria-placeholder="$t('events.filter.label')"
            :placeholder="$t('events.filter.label')"
            class="event-tags-filter white default--text mb-4"
            @change="filterByTag(tagId)"
          >
          </v-select>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              v-for="event in events"
              :key="event.id"
            >
              <v-card
                class="event-card mx-auto my-2 h-100"
                elevation="6"
                max-width="350"
              >
                <router-link :to="`/area-didattica-livelab/${event.slug}`">
                  <v-img
                    v-if="
                      event.yoast_head_json && event.yoast_head_json.og_image
                    "
                    class="event-thumb rounded-b-0"
                    height="250"
                    :src="
                      event.yoast_head_json &&
                        event.yoast_head_json.og_image[0].url
                    "
                  ></v-img>
                </router-link>

                <v-card-title class="event-title">{{
                  event.title.rendered
                }}</v-card-title>

                <v-card-text class="event-infos">
                  <div class="event-info d-flex">
                    <div class="event-info-icon pr-1">
                      <v-icon size="20" color="primary"
                        >mdi-flag-outline</v-icon
                      >
                    </div>
                    <div class="event-info-text">
                      <strong>{{ $t("events.partnership") }}</strong>
                      <div>
                        {{ event.organizzatore }}
                      </div>
                    </div>
                  </div>
                </v-card-text>

                <div class="card-actions">
                  <v-divider class="mx-4 mt-2 w-100"></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="primary"
                      dense
                      depressed
                      outlined
                      class="px-5 my-1"
                      :to="`/area-didattica-livelab/${event.slug}`"
                    >
                      {{ $t("events.pastEvents.cta") }}
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-pagination
            class="mt-12"
            color="secondary"
            v-if="pager && pager.totPages > 1"
            v-model="pageNum"
            :length="pager.totPages ? pager.totPages : 0"
            :totalVisible="$vuetify.breakpoint.xs ? 4 : 6"
            @next.stop.prevent="handlePageFilter"
            @prev="handlePageFilter"
            @input="handlePageFilter"
          ></v-pagination>
        </v-container>
      </div>
    </v-container>
    <BeCustomerCardHorizontal card="registrationHome" v-if="!isAuthenticated" />
    <FaqBar class="mt-5" v-else-if="this.page && this.page.banner_faq == '1'" />
    <NewsletterHorizontal />
  </div>
</template>
<style scoped lang="scss">
.event-tags-filter {
  max-width: max-content;
}
.event-card {
  border-radius: 8px;
  height: max-content;
}
.event-title {
  font-weight: bold;
  color: #7b7b7b;
  line-height: 1.2rem;
  font-size: 1rem;
  word-break: break-word;
  height: 110px;
  align-items: inherit;
}
.event-thumb {
  border-radius: 8px 8px 0px 0px;
}
.event-date {
  width: 48px;
  height: 48px;
  padding: 8px;
  background-color: #ffffff;
  color: #7b7b7b;
  border-radius: 8px !important;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.3rem;
  z-index: 2;
  position: absolute;
  top: 8px;
  left: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  .day,
  .month {
    display: block;
    text-align: center;
    text-transform: capitalize;
  }
}
.event-infos {
  width: initial !important;
  height: 64px;
}
.swiper-pagination {
  width: 100%;
  max-width: 100%;
  position: relative !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import CMSService from "~/service/cmService";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import VRuntimeTemplate from "v-runtime-template";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import FaqBar from "@/components/registration/FaqBar.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";

import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

export default {
  name: "PastEvents",
  props: {
    pageNum: {
      type: Number,
      default: 1
    },
    tag: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      events: null,
      tags: null,
      tagId: null,
      noPostToShow: false,
      pager: {},
      newFilters: false,
      pageTitleData: {
        title: {
          rendered: "Area didattica"
        },
        featured_image_url:
          "/p/wp-content/uploads/2021/06/area-didattica-header.png"
      }
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    PageTitle,
    Breadcrumb,
    BeCustomerCardHorizontal,
    FaqBar,
    NewsletterHorizontal
  },
  mixins: [page, clickHandler],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const arrFiltered = pathArray.filter(el => {
        return el != null && el != "";
      });
      const breadCrumbs = [{ href: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < arrFiltered.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${arrFiltered[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === arrFiltered.length,
            text: this.$route.matched[i].meta.breadCrumb || arrFiltered[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  },
  methods: {
    async fetchEvents() {
      let params = [{ filterName: "data_evento", filterValue: false }];
      if (this.tag) {
        params.push({
          filterName: "tag",
          filterValue: this.tag
        });
      }

      const res = await CMSService.getCustomPostByFilters(
        "evento",
        params,
        12,
        this.pageNum
      );

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.events = res.data;
        this.noPostToShow = false;
      } else {
        this.events = null;
        this.noPostToShow = true;
      }
    },
    async fetchEventTags() {
      const res = await CMSService.getCustomWPEndpoint(
        "tags_evento_ne",
        null,
        100,
        this.pageNum
      );

      if (res && res.data && res.data.tags && res.data.tags.length) {
        this.tags = res.data.tags;
      }
    },
    filterByTag(tag) {
      let query = {};
      if (tag) {
        query["tag"] = tag;
      }
      this.$router.push({ name: "PastEvents", query });
    },
    handlePageFilter(page) {
      let query = {
        page: page
      };
      if (this.s) {
        query.s = this.s;
      }
      if (this.course) {
        query.course = this.course;
      }
      if (this.video) {
        query.video = this.video;
      }
      this.$router.push({
        name: this.$route.name,
        query: query
      });
    }
  },
  async mounted() {
    await this.fetchEvents();
    await this.fetchEventTags();
  },
  watch: {
    pageNum() {
      this.fetchEvents();
    },
    tag() {
      this.fetchEvents();
    }
  }
};
</script>
